/* eslint-disable camelcase */
import React from 'react';
import { Button, Message, Responsive, Card, Image } from 'semantic-ui-react';

export default ({ items, removeFromCart }) => {
  // if (completed)
  //   return (
  //     <Message success>
  //       <Message.Header>Your placed!</Message.Header>
  //       <p>Congratulations. Your order and payment has been accepted.</p>
  //     </Message>
  //   )

  if (items.length === 0)
    return (
      <Message warning>
        <Message.Header>Your cart is empty</Message.Header>
        <p>
          You will need to add some items to the cart before you can checkout.
        </p>
      </Message>
    );
  const mapCartItemsToItems = items =>
    items.map(({ id, name, price, quantity, attributes }) => {
      const imageUrl = attributes.image;

      const DesktopItemImage = () => (
        <Image
          src={imageUrl}
          alt={name}
          wrapped
          style={{ background: '#f2f2f2' }}
        />
      );
      const MobileItemImage = () => (
        <Image
          src={imageUrl}
          alt={name}
          wrapped
          style={{ background: 'none' }}
        />
      );

      return {
        key: 'cart-' + id,
        // header: (
        //   <Card.Header>
        //     <Link to={`/product/${id}/`}>{name}</Link>
        //   </Card.Header>
        // ),
        image: (
          <React.Fragment>
            <Responsive as={MobileItemImage} {...Responsive.onlyMobile} />
            <Responsive
              as={DesktopItemImage}
              minWidth={Responsive.onlyTablet.minWidth}
            />
          </React.Fragment>
        ),
        extra: (
          <>
            {quantity}x R${price}
            <Button
              circular
              icon="remove"
              floated="right"
              size="tiny"
              onClick={() => removeFromCart(id)}
            />
          </>
        ),
      };
    });

  const CardMobile = () => (
    <Card.Group itemsPerRow={2} items={mapCartItemsToItems(items)} />
  );
  const CardDesktop = () => (
    <Card.Group itemsPerRow={4} items={mapCartItemsToItems(items)} />
  );

  return (
    <React.Fragment>
      <Responsive as={CardMobile} {...Responsive.onlyMobile} />
      <Responsive as={CardDesktop} minWidth={Responsive.onlyTablet.minWidth} />
    </React.Fragment>
  );
};
