import React from 'react';
import { Message } from 'semantic-ui-react';

const MessageList = ({ list }) => {
  if (list.length) {
    return <Message header="Notificações" list={list} warning />;
  }
  return <></>;
};

export default MessageList;
