import React from 'react';
import { Button, Segment, Divider } from 'semantic-ui-react';

export default ({ handleCheckout, subTotal }) => (
  <div>
    <Divider />
    <Segment clearing size="large">
      <span>
        <strong>Sub total:</strong>
        {`R$ ${subTotal}`}
      </span>
      <Button color="orange" floated="right" onClick={handleCheckout}>
        Finalizar Pedido
      </Button>
    </Segment>
  </div>
);
