import React, { useState, useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import _find from 'lodash/find';
import { Header, Loader, Divider } from 'semantic-ui-react';
import _groupBy from 'lodash/groupBy';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CartItemList from '../components/CartItemList';
import CartSummary from '../components/CartSummary';
import CartContext from '../components/Context/CartContext';
import AuthContext from '../components/Context/AuthContext';
import Messages from '../components/Messages';
import CartService from '../services/cart';

const Cart = ({ location }) => {
  const [cart, setCart] = useState(null);

  const [subTotal, setSubTotal] = useState(0);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { updateCartCount } = useContext(CartContext);
  const { token } = useContext(AuthContext);

  let summary = (
    <Messages list={['Opsss!!! Você não adicionou nenhum item ao carrinho.']} />
  );

  const mountCartItems = packageList =>
    Object.keys(packageList).map(key => {
      const items = packageList[key];
      const item = _find(items, p => p.attributes.package.name === key);
      const _ref = item.attributes.package.ref;

      return (
        <React.Fragment key={key}>
          <Header>
            <Link to={`/pacote/${_ref}/`}>{key}</Link>
          </Header>
          <CartItemList items={items} removeFromCart={handleRemoveFromCart} />
          <Divider />
        </React.Fragment>
      );
    });

  const handleRemoveFromCart = item => {
    setLoading(true);
    CartService.remove(item).then(data => {
      const cartId = localStorage.getItem('mcart');
      const CartItemsRemove = mountCartItems(
        _groupBy(data.cart, 'attributes.package.name')
      );

      if (CartItemsRemove.length) {
        setCart(CartItemsRemove);
      } else {
        setCart(null);
      }

      updateCartCount(data.getTotalQuantity, cartId);

      setSubTotal(data.subTotal);
      setLoading(false);
    });
  };

  const handleCheckout = () => {
    // CartService.clear().then(() => {
    //   const cartId = localStorage.getItem('mcart')
    //   updateCartCount(0, cartId)
    //   navigate('/')
    // })
    navigate('/checkout');
  };

  useEffect(() => {
    if (!token) {
      navigate('/login/');
    }

    setLoading(true);

    CartService.items().then(data => {
      const packageList = _groupBy(data.cart, 'attributes.package.name');

      const CartItems = mountCartItems(packageList);

      if (CartItems.length) {
        setCart(CartItems);
      }
      setMessages(data.messages);
      setLoading(false);
      setSubTotal(data.subTotal);
    });
  // eslint-disable-next-line
  }, [token]);

  if (loading)
    return (
      <Layout location={location}>
        <Loader active inline="centered" />
      </Layout>
    );

  if (cart) {
    summary = (
      <CartSummary handleCheckout={handleCheckout} subTotal={subTotal} />
    );
  }

  return (
    <Layout location={location}>
      <SEO title="Carrinho" />
      <Header as="h1">Carrinho</Header>

      <Messages list={messages} />

      {cart}

      {summary}
    </Layout>
  );
};

export default Cart;
